import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Grid, IconButton, Input,
  MenuItem,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { GET_COMPANIES } from 'const';
import { useDialog, useGetPermissions, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineCloudDownload, AiOutlineUpload } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { Company, Grouping, PaginationType, Project } from 'types';
import useGetData from '../../../../../hooks/useGetData';
import { AREAS } from '../../ReportsTemplate/Shared/sharedConsts';
import ActivateGroupingTemplate from '../ActivateGroupingTemplate';
import { DialogContent } from '../ActivateGroupingTemplate/ActivationGroupingStyles';
import GroupingConfirmationTemplate from '../GroupingConfirmationTemplate';
import GroupingDownloadTemplate from '../GroupingDownloadTemplate';
import GroupingUploadTemplate from '../GroupingUploadTemplate';
import GroupingTemplateApiLoading from '../Shared/GroupingTemplate.loading';
import {
  COMPANY_SERVICE_GROUPING_STATUS,
  GET_ENABLED_PROJECTS_PATH,
  GROUPING_STATUS,
  createFilterModel,
  defaultFilterModel,
  defaultListGroupingTemplateFilter,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
  rowsMapper,
  updateModifiedGroupings
} from './ListGroupingTemplate.const';
import {
  ListGroupingTemplateFilterSchema,
  ListGroupingTemplateFilterType
} from './ListGroupingTemplate.schema';
import { ListGroupingTemplateProps } from './ListGroupingTemplate.types';

const ListGroupingTemplate = ({ ...props }: ListGroupingTemplateProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ListGroupingTemplateFilterType>({
    defaultValues: defaultListGroupingTemplateFilter,
    resolver: yupResolver(ListGroupingTemplateFilterSchema),
  });

  const { projectsIds: projects, name, area, publicServices, status, referenceStatus } = watch();

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [action, setAction] = useState<'remove' | 'edit' | 'upload' | 'download' | 'activate' | 'confirm' | undefined>(
    undefined,
  );
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | 'upload' | 'download' | 'activate' | 'confirm' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const { canEdit, canInsert } = useGetPermissions();

  const { data: projectsFetch, loading: loadingProjects } = useGetData<Project>(
    GET_ENABLED_PROJECTS_PATH
  );
  const { data: companiesFetch, loading: loadingCompanies } = useGetData<Company>(
    GET_COMPANIES
  );


  const [isDownloading, setIsDownloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [filterModel, setFilterModel] = useState<filterModelType<Grouping>[]>(defaultFilterModel);
  const [currentfilterModel, setCurrentFilterModel] = useState<filterModelType<Grouping>[]>(defaultFilterModel);
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [modifiedGroupings, setModifiedGroupings] = useState<Grouping[]>([]);
  const [selectedGroupingsToActivate, setSelectedGroupingsToActivate] = useState<Grouping[] | undefined>();
  const [statusToggle, setStatusToggle] = useState<boolean>(true);
  const [isGlobalToggle, setIsGlobalToggle] = useState<boolean>(false);


  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
      setActionSnackBar('edit');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
    setIsFiltering(false)
  }, [onCloseModal]);

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
      setActionSnackBar('upload')
    },
    [onOpen],
  );
  const onActivate = useCallback(
    () => {
      onOpen(0);
      setAction('activate');
      setActionSnackBar('activate')
    },
    [onOpen],
  );

  const onDownload = useCallback(
    () => {
      onOpen(0);
      setAction('download');
    },
    [onOpen],
  );

  const onConfirm = useCallback(
    () => {
      onOpen(0);
      setAction('confirm');
    },
    [onOpen],
  );

  const dialogTitle = (): string => {
    if (action === "upload") {
      return "Carga excel para suscribir inmuebles";
    }
    if (action === "activate") {
      const subject = selectedGroupingsToActivate?.length === 1 ? "el inmueble" : "los inmuebles";
      return `Selecciona un área para ${subject} que vas a activar`;
    }
    if (action === "download") {
      return "Descarga reporte de inmuebles y servicios públicos";
    } if (action === "confirm") {
      return "Confirmación de edición de inmuebles";
    }

    else {
      return "";
    }
  };

  const onCheck = (newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
    setIsGlobalToggle(false);
    const checked = newState;
    const grouping = process?.result.find((grouping) => grouping.id === sourceElement);

    if (grouping) {
      setSelectedGroupingsToActivate([grouping]);
      if (checked && !grouping.area) {
        onActivate();
        return;
      }
      grouping.status = checked ? "ACTIVO" : "INACTIVO";
      updateModifiedGroupings(setModifiedGroupings, [grouping]);
    }
  }

  const onMultipleChecks = (newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
    setIsGlobalToggle(true);
    const checked = newState;

    if (checked && process?.result.some((grouping) => !grouping.area)) {
      const groupings = process?.result.filter((grouping) => !grouping.area);
      setSelectedGroupingsToActivate(groupings);
      onActivate();
      return;
    }
    setStatusToggle(newState);
    process?.result.forEach((grouping) => {
      grouping.status = checked ? "ACTIVO" : "INACTIVO"
    });
    updateModifiedGroupings(setModifiedGroupings, process?.result || []);
  }

  const {
    loading,
    error,
    handleData,
    getData,
    data: process
  } = useGetTable<PaginationType<Grouping>, tableModelType<Grouping>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel: defaultFilterModel,
  });

  const tableControllers = useTable<Grouping>(
    {
      filterModel,
      sortModel: defaultSortModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Grouping>, 'paginationModel'>,
    ) => Promise<PaginationType<Grouping>>,
  );

  const columns = useMemo(
    () => getColumns(action, setAction, onCheck, canEdit),
    [confirmEdit, process],
  );

  const handleStatusToggle = (newState: boolean, sourceElement: string | number | number[] | string[] | undefined) => {
    if (sourceElement === 'status') {
      onMultipleChecks(newState, sourceElement);
    }
  }

  const getToggleOptions = () => {
    return {
      status: {
        toggled: statusToggle,
        onToggleChange: handleStatusToggle
      }
    }
  }


  //TODO regresar esto a useCallback cuando se saque la lógica de abrir el modal
  const onSubmit = (
    filterListGroupingTemplate: ListGroupingTemplateFilterType
  ) => {
    const {
      name: nameForm,
      area: areaForm,
      projectsIds: projectsForm,
      publicServices: publicServicesForm,
      status: statusForm,
      referenceStatus: referenceStatus,
    } = filterListGroupingTemplate;

    const modelFilter = createFilterModel(nameForm, areaForm, projects, publicServicesForm, statusForm, referenceStatus);

    //TODO: sacar esto a un const?
    if (modifiedGroupings.length > 0) {
      setCurrentFilterModel(modelFilter as filterModelType<Grouping>[])
      setIsFiltering(true);
      onConfirm();
      return;
    }

    tableControllers.paginationModel.page = 0;
    setFilterModel(modelFilter as filterModelType<Grouping>[]);
  };

  return (
    <>
      <Table<Grouping>
        {...tableControllers}
        numberOfVisibleColumns={4}
        columns={columns}
        rows={rowsMapper(process?.result, modifiedGroupings) || []}
        error={!!error}
        loading={loading && (process?.result?.length ?? 0) > 0}
        exportOptions={{
          filename: `Inmuebles`,
        }}
        toggleOptions={getToggleOptions()}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item>
              <IconButton
                variant="contained"
                href="/pages/servicios-publicos/inmueble/create"
                info="Crear inmueble"
              >
                <IoAdd />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item xs={3}>
                    <Input
                      fullWidth
                      label="Inmueble"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="area"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Área"
                            fullWidth
                            enableFilter
                          >
                            {AREAS
                              .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                              .map(({ value, name }) => (
                                <MenuItem
                                  key={value}
                                  label={`${name}`}
                                  value={`${value}`}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="projectsIds"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Proyectos"
                            fullWidth
                            multiple
                            enableFilter
                          >
                            {projectsFetch ?
                              projectsFetch
                                .sort((proj1, proj2) => (proj1.name > proj2.name) ? 1 : -1)
                                .map(({ id, name }) => (
                                  <MenuItem
                                    key={name}
                                    label={`${name}`}
                                    value={`${id}`}
                                  >
                                    {name}
                                  </MenuItem>
                                )) : <></>}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="publicServices"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Compañía"
                            fullWidth
                            multiple
                            enableFilter
                          >
                            {companiesFetch ?
                              companiesFetch
                                .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                                .map(({ id, name }) => (
                                  <MenuItem
                                    key={name}
                                    label={`${name}`}
                                    value={`${id}`}
                                  >
                                    {name}
                                  </MenuItem>
                                )) : <></>}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Estado"
                            fullWidth
                            enableFilter
                          >
                            {GROUPING_STATUS
                              .sort((status1, status2) => (status1.name > status2.name) ? 1 : -1)
                              .map(({ value, name }) => (
                                <MenuItem
                                  key={value}
                                  label={`${name}`}
                                  value={`${value}`}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="referenceStatus"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Ref."
                            fullWidth
                            enableFilter
                            multiple
                          >
                            {COMPANY_SERVICE_GROUPING_STATUS
                              .sort((status1, status2) => (status1.name > status2.name) ? 1 : -1)
                              .map(({ value, name }) => (
                                <MenuItem
                                  key={value}
                                  label={`${name}`}
                                  value={`${value}`}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={onConfirm}
                      disabled={modifiedGroupings.length === 0}
                      loading={isUpdating}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item>
              {/* (canInsert() && canEdit()) */ true && <Grid item>
                <IconButton
                  info="Cargar configuración masiva de inmuebles"
                  color="primary"
                  onClick={() => onUpload()}
                >
                  <AiOutlineUpload />
                </IconButton>
              </Grid>}
              <Grid item>
                <IconButton
                  info="Descarga configuración de inmuebles"
                  color="primary"
                  loading={isDownloading}
                  onClick={() => onDownload()}
                >
                  <AiOutlineCloudDownload />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={dialogTitle()}
      >
        <>
          {!waitingResponse ? (
            <Grid container spacing={3} justify="flex-end" >
              <Grid item xs={12} >
                {action === 'upload' && (
                  <GroupingUploadTemplate
                    waitingResponse={waitingResponse}
                    setWaitingResponse={setWaitingResponse}
                    action={action}
                    setAction={setAction}
                    open={open}
                    setOpen={setOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    setErrorMessage={setErrorMessage}
                    filterModel={filterModel}
                    setFilterModel={setFilterModel}
                  />
                )}
                {action === 'download' && (
                  <GroupingDownloadTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    setErrorMessage={setErrorMessage}
                    groupingDownloadFilterModel={{
                      groupingName: name,
                      area,
                      projects,
                      publicServices,
                      status,
                      referenceStatus,
                    }}
                    projectList={projectsFetch}
                    publicServicesList={companiesFetch}
                  />
                )}
                {action === 'activate' && (
                  <DialogContent>
                    <ActivateGroupingTemplate
                      setOpen={setOpen}
                      onClose={onClose}
                      setErrorMessage={setErrorMessage}
                      groupingList={modifiedGroupings}
                      pageGroupingList={process?.result || []}
                      selectedGroupings={selectedGroupingsToActivate}
                      setModifiedGroupings={setModifiedGroupings}
                      setStatusToggle={setStatusToggle}
                      isGlobal={isGlobalToggle}
                    />
                  </DialogContent>
                )}
                {action === 'confirm' && (
                  <GroupingConfirmationTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    setErrorMessage={setErrorMessage}
                    groupingList={modifiedGroupings}
                    setModifiedGroupings={setModifiedGroupings}
                    setActionSnackBar={setActionSnackBar}
                    filterModel={filterModel}
                    setFilterModel={setFilterModel}
                    isFiltering={isFiltering}
                    currentFilterModel={currentfilterModel}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <GroupingTemplateApiLoading />)}
        </>
      </Dialog >
      <SnackBar
        wait={open !== 'warning' ? 5000 : undefined}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          actionSnackBar === 'edit' &&
          'Se editó el estado del inmueble exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'remove' &&
          'Se eliminó el inmueble exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'upload' &&
          'El archivo se cargó correctamente'}
        {open === 'success' &&
          actionSnackBar === 'activate' &&
          'Se actualizaron los inmuebles exitosamente'}
        {open === 'warning' && errorMessage}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListGroupingTemplate;
