import React, { forwardRef, useCallback, useRef, useState } from 'react';

import { RiArrowDownSLine } from 'react-icons/ri';

import Tooltip from '../Info';

import { useControlled, useForkRef, useTheme } from 'hooks';

import MenuItem, { MenuItemProps } from '../MenuItem';

import { themeType } from 'types';
import { darken, lighten } from 'utils';
import Menu from '../Menu';
import Popover from '../Popover';
import {
  Adornment,
  Container,
  HelperText,
  Input,
  InputRoot,
  Label,
  Placeholder,
  Root,
} from './Select.styles';

type valueType = string;

export type SelectProps = {
  id?: string;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  readOnly?: boolean;
  enableFilter?: boolean;
  required?: boolean;
  value?: valueType;
  defaultValue?: valueType;
  name?: string;
  textIfNoExitsRecords?: string;
  info?: string | { content?: string; title?: string };
  maxItems?: number;
  placeholderInSearch?: string;
  textIfNoMatchesFound?: (search: string) => string;
  placeholder?: string;
  multiple?: boolean;
  onChange?: (
    e: React.SyntheticEvent,
    child:
      | React.ReactElement<MenuItemProps>
      | React.ReactElement<MenuItemProps>[],
  ) => void;
  open?: boolean;
  onOpen?: (e: React.SyntheticEvent) => void;
  onFilter?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  groupBy?: (option: SelectProps['children']) => string;
  helperText?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  children?:
  | React.ReactElement<MenuItemProps>[]
  | React.ReactElement<MenuItemProps>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'defaultValue'>;

function areEqualValues(a: any, b: any) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
}

function isEmpty(display: any) {
  return display == null || (typeof display === 'string' && !display.trim());
}

const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      id,
      label = '',
      textIfNoExitsRecords = 'No se encontraron opciones',
      disabled,
      maxItems = 10,
      readOnly,
      required,
      fullWidth = false,
      error = false,
      inputProps = {},
      value: valueProp,
      defaultValue,
      enableFilter,
      onFilter,
      onChange,
      multiple,
      onBlur: defaultOnBlur,
      onFocus: defaultOnFocus,
      onOpen,
      onClose,
      name,
      info,
      open: openProp,
      helperText = '',
      placeholder,
      endAdornment,
      startAdornment,
      textIfNoMatchesFound = (search: string) =>
        `No se han encontrado coincidencias que contengan '${search}'`,
      placeholderInSearch,
      children,
      ...props
    },
    ref,
  ) => {
    const content = typeof info === 'object' ? info.content : info;
    const title = typeof info === 'object' ? info.title : undefined;

    let display: any;
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const widthContainer = useRef<number>(0);
    const searchRef = useRef<HTMLInputElement>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const handleRef = useForkRef(ref, inputRef);
    const { current: isOpenControlled } = React.useRef(openProp != null);
    const [value, setValue] = useControlled<valueType>({
      controlled: valueProp,
      default: defaultValue,
    });
    const [openState, setOpenState] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;
      setSearch(value);
      if (onFilter) onFilter(e);
    };

    React.useImperativeHandle(
      handleRef,
      () => ({
        node: inputRef.current,
        value,
      }),
      [value],
    );

    const update = useCallback(
      (open: boolean, event: React.SyntheticEvent) => {
        if (open) {
          if (onOpen) {
            onOpen(event);
          }
        } else if (onClose) {
          onClose(event);
        }
        // if (open) setSearch("");

        if (!isOpenControlled) setOpenState(open);
      },
      [isOpenControlled, onOpen, onClose],
    );

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        e.stopPropagation();
        return;
      }

      if (defaultOnFocus) defaultOnFocus(e);
      setFocus(true);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        e.stopPropagation();
        return;
      }

      if (defaultOnBlur) defaultOnBlur(e);
      setFocus(false);
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.button !== 0) {
        return;
      }

      if (containerRef.current && !open) {
        widthContainer.current =
          containerRef.current.getBoundingClientRect().width;
      }

      event.preventDefault();
      update(true, event);

      if (enableFilter && searchRef && searchRef.current)
        searchRef.current.focus();
    };

    const childrenArray = React.Children.toArray(
      children,
    ) as React.ReactElement<MenuItemProps>[];

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const index = childrenArray
        .map((child) => child.props.value)
        .indexOf(event.target.value);

      if (index === -1) {
        return;
      }

      const selectedChild = childrenArray[
        index
      ] as React.ReactElement<MenuItemProps>;

      if (multiple) {
        const copyValues = value ? value.split(',') : [];
        const currentIndex = copyValues.findIndex(
          (value: string | number) => value === selectedChild.props.value,
        );
        if (currentIndex) copyValues.splice(currentIndex, 1);
        else copyValues.push(event.target.value);
        setValue(copyValues.join(','));

        event.target.value = copyValues.join(',');
        event.target.name = name as string;

        if (onChange)
          onChange(
            event,
            childrenArray.filter(
              ({ props }) => props.value && copyValues.includes(props.value),
            ),
          );
      } else {
        event.target.value = selectedChild.props.value as string;
        event.target.name = name as string;

        setValue(selectedChild.props.value);
        if (onChange) onChange(event, selectedChild);
      }
    };

    const handleItemClick =
      (child: React.ReactElement<MenuItemProps>) =>
        (event: React.MouseEvent<HTMLDivElement>) => {
          if (!multiple) update(false, event);

          if (child.props.onClick) {
            child.props.onClick(event);
          }

          const newValue = child.props.value;

          if (multiple) {
            const copyValues = value ? value.split(',') : [];
            const currentIndex = copyValues.findIndex(
              (value: string | number) => value === newValue,
            );
            if (currentIndex === -1 && newValue) copyValues.push(newValue);
            else if (currentIndex >= 0 && newValue)
              copyValues.splice(currentIndex, 1);
            setValue(copyValues.join(','));

            event.persist();
            Object.defineProperty(event, 'target', {
              writable: true,
              value: { value: copyValues.join(','), name },
            });

            if (onChange)
              onChange(
                event,
                childrenArray.filter(
                  ({ props }) => props.value && copyValues.includes(props.value),
                ),
              );
            // if (value === newValue) {
            //   event.persist();
            //   Object.defineProperty(event, 'target', {
            //     writable: true,
            //     value: { value: '', name },
            //   });
            //   setValue('');
            //   onChange?.(event, child);
            //   return;
            // }
          } else {
            if (value === newValue) {
              if (!required) {
                event.persist();
                Object.defineProperty(event, 'target', {
                  writable: true,
                  value: { value: '', name },
                });
                setValue('');
                onChange?.(event, child);
              }
              return;
            }
            event.persist();
            Object.defineProperty(event, 'target', {
              writable: true,
              value: { value: newValue, name },
            });
            setValue(newValue);
            if (onChange) onChange(event, child);
          }
        };

    const open = isOpenControlled ? openProp : openState;

    const handleBlur = (event: React.SyntheticEvent) => {
      if (!open && defaultOnBlur) {
        event.persist();
        Object.defineProperty(event, 'target', {
          writable: true,
          value: { value, name },
        });
        defaultOnBlur(event);
      }
    };

    const handleClose = (e: any) => {
      update(false, e);
    };

    const copyItems = childrenArray.filter((child) => {
      return (
        !enableFilter ||
        (child.props.label || child.props.value || '')
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });

    const items = copyItems.map((child, idx) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      let selected = false;

      if (multiple) {
        selected = child.props.value
          ? value.split(',').includes(child.props.value)
          : false;
        if (selected) {
          display = `${display ? display : ''}${child.props.children}, `;
        }
      } else {
        selected = areEqualValues(value, child.props.value);
        if (selected) display = child.props.children;
      }

      return React.cloneElement(child, {
        'aria-selected': selected ? 'true' : undefined,
        onClick: handleItemClick(child),
        onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === ' ') {
            event.preventDefault();
          }

          if (child.props.onKeyUp) {
            child.props.onKeyUp(event);
          }
        },
        disabledBorder: idx + 1 === copyItems.length,
        role: 'option',
        selected,
        tabIndex: selected ? 0 : -1,
        label: undefined,
        'data-label': child.props.label,
        value: undefined, // The value is most likely not a valid HTML attribute.
        'data-value': child.props.value, // Instead, we provide it as a data attribute.
      });
    });

    const { theme } = useTheme();
    const { palette, common, mode } = theme;

    if (!display && !!placeholder)
      display = <Placeholder>{placeholder}</Placeholder>;

    const isBlank = isEmpty(display);

    const styleLabel: Record<themeType, object> = {
      dark: {
        '--color': open || focus ? palette.primary : lighten(palette.grey, 0.5),
      },
      light: {
        '--color':
          !open || !focus
            ? lighten(common.black, 0.1)
            : lighten(common.black, 0.3),
      },
    };

    const styleInputRoot: Record<themeType, object> = {
      dark: {
        '--color': error ? palette.error : darken(theme.common.white, 0.3),
        '--border': error
          ? palette.error
          : open
            ? palette.primary
            : lighten(palette.background, 0.05),
        '--scale': error || open ? 'scaleX(1)' : 'scaleX(0)',
      },
      light: {
        '--color': error ? palette.error : theme.common.black,
        '--border': error ? palette.error : theme.palette.background,
        '--scale': error || open ? 'scaleX(1)' : 'scaleX(0)',
      },
    };

    const styleHelperText: Record<themeType, object> = {
      dark: {
        '--color': error ? palette.error : darken(theme.common.white, 0.5),
      },
      light: {
        '--color': error ? palette.error : darken(theme.common.white, 0.5),
      },
    };

    const styleAdornment: Record<themeType, object> = {
      dark: { '--color': 'inherit' },
      light: { '--color': 'inherit' },
    };

    const newInputProps = {
      ...inputProps,
      $disabledTopPadding: !label,
      id,
      value: multiple
        ? childrenArray
          .filter(
            ({ props }) =>
              props.value &&
              (value ? value.split(',') : []).includes(props.value),
          )
          .map(({ props }) => props.children || props.label)
          .join(',')
        : (value as string),
      name,
      defaultValue: defaultValue as string,
      onChange: handleChange,
      onFocus,
      onBlur: onBlur,
      ref: handleRef,
      // "aria-hidden": true,
      disabled,
      readOnly,
      required,
      placeholder,
    };

    const labelProps = {
      style: styleLabel[mode],
      $disabled: disabled,
      htmlFor: id,
      shrink: open || !isBlank || !!startAdornment || !!placeholder,
    };

    const adornmentProps = {
      style: styleAdornment[mode],
    };

    const inputRootProps = {
      theme,
      style: styleInputRoot[mode],
      disabled,
      readOnly,
    };

    const helperTextProps = {
      style: styleHelperText[mode],
      children: helperText,
    };

    const handleClearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation(); // Evita que el menú se abra al hacer clic en la "X"
      setValue('');  // Limpiar el estado
      if (onChange) {
        const fakeEvent = { target: { value: '', name } } as unknown as React.ChangeEvent<HTMLInputElement>;
        onChange(fakeEvent, []);
      }
    };

    return (
      <Root
        fullWidth={fullWidth}
        disabled={disabled}
        onMouseDown={disabled || readOnly ? undefined : handleMouseDown}
        onBlur={handleBlur}
        onFocus={defaultOnFocus}
        role="presentation"
        ref={rootRef}
        {...props}
      >
        {label &&
          (info ? (
            <Label variant="p1" component="label" {...labelProps}>
              <Tooltip content={content} title={title}>
                {label} {required && '*'}
              </Tooltip>
            </Label>
          ) : (
            <Label variant="p1" component="label" {...labelProps}>
              {label} {required && '*'}
            </Label>
          ))}

        <InputRoot {...inputRootProps}>
          {startAdornment && (
            <Adornment orientation="start" {...adornmentProps}>
              {startAdornment}
            </Adornment>
          )}

          <Container
            disabled={disabled || readOnly}
            ref={containerRef}
            $disabledTopPadding={!label}
          >
            <div
              style={{
                display: enableFilter && open ? 'none' : 'block',
                alignItems: 'flex-end',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                maxWidth: widthContainer.current - 28,
              }}
            >
              {isEmpty(display) ? (
                <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
              ) : multiple ? (
                display.slice(0, -2)
              ) : (
                display
              )}
            </div>

            {enableFilter && open && (
              <Input
                $show
                ref={searchRef}
                autoFocus
                placeholder={placeholderInSearch}
                value={search}
                onChange={handleSearch}
                style={{
                  width: widthContainer.current - 28,
                  padding: 0,
                }}
              />
            )}
          </Container>

          <Input {...newInputProps} style={{ paddingRight: '30px' }} />

          {/* Botón de limpiar selección */}
          {multiple && value && (
            <button
              onClick={handleClearSelection}
              style={{
                position: 'absolute',
                right: '2px',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '10px',
                color: '#fff', // Ajusta el color según tu diseño
                zIndex: 2000
              }}
              aria-label="Clear selection"
            >
              ✕
            </button>
          )}

          {endAdornment ? (
            <Adornment orientation="end" {...adornmentProps}>
              {endAdornment}
            </Adornment>
          ) : (
            <Adornment orientation="end">
              <RiArrowDownSLine />
            </Adornment>
          )}
        </InputRoot>
        <Popover
          id={id}
          open={!!open}
          anchorEl={containerRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          disableEnforceFocus={enableFilter}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Menu
            maxItems={maxItems}
            style={{ width: 'fit-content' }}
          >
            {items.length > 0 ? (
              items
            ) : (
              <MenuItem disabled disabledBorder>
                {enableFilter
                  ? textIfNoMatchesFound(search)
                  : textIfNoExitsRecords}
              </MenuItem>
            )}
          </Menu>
        </Popover>
        <HelperText {...helperTextProps} />
      </Root>
    );
  },
);

export default Select;
