import {
  Button,
  Grid,
  Typography
} from 'components/atoms';
import { format } from 'date-fns';
import { filterModelType } from 'hooks/useTable';
import { useState } from 'react';
import { FlatHistory } from 'types';
import { COMMON_OPTIONS, DATE_FORMAT_WITH_OUT_TIME } from '../../../../../const';
import { useDownloadFile } from '../../../../../hooks';
import { createFilterModel } from '../HistoryPendingTemplate/HistoryPendingTemplate.const';
import HistoryTemplateApiLoading from '../Shared/HistoryTemplate.loading';
import { areaFormatter, getProjectsNamesByIds, getPubliServiceCodesByIds } from '../Shared/sharedConsts';
import { downloadPdf, getFileNameByFileType } from './HistoryPendingDownloadTemplate.const';
import { HistoryPendingDownloadTemplateProps } from './HistoryPendingDownloadTemplate.types';


/**
 * Este componente implementa el modal para realizar
 * descargas masivas de facturas.
 */
const HistoryPendingDownloadTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    historyDownloadFilterModel,
    projectList,
    publicServicesList,
    ...props
  }: HistoryPendingDownloadTemplateProps
) => {

  const [isDownloading, setIsDownloading] = useState(false);
  const { reference, area, projects, publicServices, dateRange, grouping } = historyDownloadFilterModel;
  let isChecked: boolean = true;
  /**
   * Block of code to use the hook to download files
   */
  const preDownloading = () => {
    setIsDownloading(true);
  };

  const postDownloading = () => {
    onClose();
    setIsDownloading(false);
  };

  const onErrorDownloadFile = (errorMessage: string | undefined) => {
    onClose();
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setOpen('warning')
    } else {
      setOpen('error')
    }
  };
  const getFileName = () => {
    return getFileNameByFileType(
      isChecked ? "pdf" : "zip"
    );
  };


  const downloadFiles = () => {
    const newFilterModel = createFilterModel({ reference, area, dateRange, projects, publicServices, grouping });
    const result = downloadPdf(
      isChecked ? "pdf" : "zip",
      newFilterModel as filterModelType<FlatHistory>[]
    )
    return result
  }


  const { download } = useDownloadFile({
    apiDefinition: downloadFiles,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });


  const onSubmit = (isCheckedFile: boolean) => {
    isChecked = isCheckedFile;
    try {
      download();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };


  return (
    <>
      {(!isDownloading) ? (
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            <Grid item xs={12}>
              {/*               {(isDownloaded === 'true') && (
                <Typography align='center' variant='h2' >
                  ¿Estas seguro que deseas descargar todas las facturas previamente descargadas, registradas entre el {`${dateRange.startDate?.toLocaleDateString('es-CO')}`} y el {`${dateRange.endDate?.toLocaleDateString('es-CO')}`}
                  {(area) ? `, para el área: ${areaFormatter(area)}?` : '?'}
                </Typography>
              )}
              {(isDownloaded === 'false') && (
                <Typography align='center' variant='h2'>
                  ¿Estas seguro que deseas descargar todas las facturas sin descargar, registradas entre el {`${dateRange.startDate?.toLocaleDateString('es-CO')}`} y el {`${dateRange.endDate?.toLocaleDateString('es-CO')}`}
                  {(area) ? `, para el área: ${areaFormatter(area)}?` : '?'}
                </Typography>
              )}
              {(isDownloaded === 'all') && (
                <Typography align='center' variant='h2'>
                  ¿Estas seguro que deseas descargar todas las facturas registradas entre el {`${dateRange.startDate?.toLocaleDateString('es-CO')}`} y el {`${dateRange.endDate?.toLocaleDateString('es-CO')}`}
                  {(area) ? `, para el área: ${areaFormatter(area)}?` : '?'}
                </Typography>
              )} */}
              <Typography align='center' variant='h2' >
                ¿Estas seguro que deseas descargar facturas con fecha límite de pago entre el

                {(dateRange.startDate) && ` ${format(new Date(dateRange.startDate), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS)}
                `} y el {(dateRange.endDate) && `${format(new Date(dateRange.endDate), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS)}`}

                {(area) && `, para el área: ${areaFormatter(area)}`}

                {projects && (
                  `; de los proyectos: ${getProjectsNamesByIds(projects, projectList!)
                  }`
                )}

                {publicServices && (
                  `; asociadas a las compañías: ${getPubliServiceCodesByIds(publicServices, publicServicesList!)
                  }`
                )}
                ?
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info="Descarga reportes históricos pagados"
                color="primary"
                variant="contained"
                onClick={() => onSubmit(true)}
              >
                Descargar pdf
              </Button>
            </Grid>
            <Grid item>
              <Button
                info="Descarga reportes históricos pagados"
                color="primary"
                variant="contained"
                onClick={() => onSubmit(false)}
              >
                Descargar zip
              </Button>
            </Grid>
          </Grid>
        </Grid>) : (
        <HistoryTemplateApiLoading />
      )}
    </>
  );
};


export default HistoryPendingDownloadTemplate;
