import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DateRangePicker,
  Dialog,
  Grid, IconButton,
  Input,
  MenuItem, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { useCustomerContext } from 'contexts/CustomerContext';
import { format } from 'date-fns';
import { useDialog, useDownloadFile, useGetPermissions, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineClear, AiOutlineCloudDownload, AiOutlineSearch, AiOutlineUpload } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { Company, customerType, FlatHistory, PaginationType, Project } from 'types';
import { Consumption } from 'types/entities/consumption';
import { joinObjectWithFormat } from 'utils';
import { DATE_FORMAT_BACK_WITH_OUT_TIME, GET_COMPANIES, GET_PROJECTS } from '../../../../../const';
import useGetData from '../../../../../hooks/useGetData';
import { DateRangePickerValue } from '../../../../atoms/DateRangePicker/DateRangePicker';
import HistoryConfirmationClientActionTemplate from '../HistoryConfirmationClientActionTemplate';
import HistoryConsumptionTemplate from '../HistoryConsumptionTemplate';
import HistoryPendingDownloadTemplate from '../HistoryPendingDownloadTemplate';
import HistoryUploadTemplate from '../HistoryUploadTemplate';
import HistoryTemplateApiLoading from '../Shared/HistoryTemplate.loading';
import HistoryTemplatePdf from '../Shared/HistoryTemplate.pdf';
import { AREAS } from '../Shared/sharedConsts';
import { CLIENT_ACTIONS } from './ClientActions.const';
import {
  actions,
  createFilterModel,
  defaultPagination,
  defaultSortModel,
  downloadOnePdf,
  downloadPdf,
  fetchProcess,
  getColumns,
  getDefaultFilter,
  getDefaultHistoryTemplateFilter, getDialogTitle, getFileNameByInvoiceNumber,
  updateModifiedHistory,
} from './HistoryPendingTemplate.const';
import {
  HistoryTemplateFilterSchema,
  HistoryTemplateFilterType
} from './HistoryPendingTemplate.schema';
import { HistoryTemplateProps } from './HistoryPendingTemplate.types';

const HistoryPendingTemplate = ({ ...props }: HistoryTemplateProps) => {
  const { action: userAction, cp } = useParams()
  const { name: customerName } = useCustomerContext();
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const [action, setAction] = useState<actions>(undefined,);
  const [actionSnackBar, setActionSnackBar] = useState<actions>(undefined,);
  const [open, setOpen] = useState<severityType>();
  const [modifiedHistories, setModifiedHistories] = useState<FlatHistory[]>([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { data: projectsFetch, loading: loadingProjects } = useGetData<Project>(
    GET_PROJECTS
  );
  const { data: companiesFetch, loading: loadingCompanies } = useGetData<Company>(
    GET_COMPANIES
  );
  const { canEdit } = useGetPermissions();
  const [consumptionList, setConsumptionList] = useState<Consumption[]>([]);
  const [idHistory, setIdHistory] = useState<number>(0);
  const [groupingName, setGroupingName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [valueTotal, setValueTotal] = useState<number>(0);

  let defaultFilter: filterModelType<FlatHistory>[] = getDefaultFilter(customerName, userAction, cp);
  let downloadType: 'single' | 'multiple' = 'multiple';
  //TODO: arreglar 0 quemado
  let selectedId: number;
  let selectedInvoiceNumber: string;

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<HistoryTemplateFilterType>({
    defaultValues: getDefaultHistoryTemplateFilter(cp),
    resolver: yupResolver(HistoryTemplateFilterSchema),
  });

  const { reference, area, projects, publicServices, grouping, dateRange, accountsPayable } = watch();

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
    },
    [onOpen],
  );

  const onDownload = useCallback(
    () => {

      onOpen(0);
      setAction('download');
    },
    [onOpen],
  );


  const onDownloadOne = useCallback(
    () => {
      onOpen(0);
      setAction('downloadOne');
    },
    [onOpen],
  );

  const onViewConsumptionModal = useCallback(
    () => {
      onOpen(0);
      setAction('consumption');
    },
    [onOpen],
  );

  const onDisassociateCPModal = useCallback(
    () => {
      onOpen(0);
      setAction('disassociate');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    setFile(undefined);
    onCloseModal();
  }, [onCloseModal]);

  const [filterModel, setFilterModel] = useState<filterModelType<FlatHistory>[]>(defaultFilter);

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
  } = useGetTable<PaginationType<FlatHistory>, tableModelType<FlatHistory>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<FlatHistory>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<FlatHistory>, 'paginationModel'>,
    ) => Promise<PaginationType<FlatHistory>>,
  );

  /**
   * TODO: delete this comment
   * Block of code to use the custom hook to download files
   */

  const preDownloading = () => { };
  const postDownloading = () => { };
  const onErrorDownloadFile = (errorMessage: string | undefined) => {
    onClose()
    setOpen('error');
  };
  const getFileName = () => {
    return getFileNameByInvoiceNumber(
      selectedInvoiceNumber || ""
    );
  };

  const [file, setFile] = useState<Blob | undefined>();


  const downloadFiles = () => {
    if (downloadType === 'single') {
      return downloadOnePdf(selectedId)
    }
    const newFilterModel = createFilterModel({ reference, area, dateRange, projects, publicServices, grouping, accountsPayable });
    return downloadPdf(newFilterModel as filterModelType<FlatHistory>[]);
  }

  const { ref, url, download, name, view } = useDownloadFile({
    apiDefinition: downloadFiles,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  const onDownloadSingleFile = async (id: number, invoiceNumber: string) => {
    try {
      downloadType = 'single'
      selectedId = id;
      selectedInvoiceNumber = invoiceNumber;
      download();
    } catch (error) {
      setOpen('error');
      onClose();
    }

    finally {
      downloadType = 'multiple'
    }
  }

  const onViewOne = async (id: number, invoiceNumber: string) => {
    onDownloadOne();
    try {
      downloadType = 'single'
      selectedId = id;
      selectedInvoiceNumber = invoiceNumber;
      view().then(data => {
        setFile(data)
      });
    } finally {
      downloadType = 'multiple'
    }
  }

  const onDisassociateCP = async (id: number, groupingName: string, projectName: string) => {
    setIdHistory(id);
    setGroupingName(groupingName);
    setProjectName(projectName);
    onDisassociateCPModal();
  }

  const onViewConsumption = async (consumptions: Consumption[]) => {
    onViewConsumptionModal();
    setConsumptionList(consumptions);
  }

  const onActivate = useCallback(
    () => {
      onOpen(0);
      setAction('activate');
      setActionSnackBar('activate')
    },
    [onOpen],
  );

  const onCheck = (newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
    const checked = newState;
    const histories = process?.result.find((history) => history.id === sourceElement);

    if (!checked) {
      const newModifiedHistories = modifiedHistories.filter((history) => history.id !== sourceElement);
      setModifiedHistories(newModifiedHistories);
      return;
    }

    if (histories) {
      if (checked && !histories.area) {
        onActivate();
        return;
      }
      updateModifiedHistory(setModifiedHistories, [histories]);
    }
  }

  const onMultipleChecks = (newState: boolean, sourceElement: string | number | number[] | string[] | undefined): void => {
    setStatusToggle(newState);
    if (!newState) {
      setModifiedHistories([]);
      return;
    }
    updateModifiedHistory(setModifiedHistories, process?.result || []);
  }

  const handleStatusToggle = (
    newState: boolean,
    sourceElement: string | number | number[] | string[] | undefined
  ) => {
    if (sourceElement === 'accountingPlan') {
      onMultipleChecks(newState, sourceElement);
    }
  }

  const getToggleOptions = () => {
    return {
      accountingPlan: {
        toggled: statusToggle,
        onToggleChange: handleStatusToggle
      }
    }
  }

  const onConfirm = useCallback(
    () => {
      onOpen(0);
      setAction('confirm');
    },
    [onOpen],
  );

  const columns = useMemo(
    () => getColumns(onDownloadSingleFile, ref, url, name, onViewOne, onViewConsumption, userAction, customerName, onCheck, modifiedHistories, cp, onDisassociateCP),
    [onDownloadSingleFile, ref, url, name, userAction, customerName, modifiedHistories, cp],
  );

  const onSubmit = (filterHistoryTemplate: HistoryTemplateFilterType) => {

    const {
      reference,
      area,
      projects,
      publicServices,
      dateRange,
      accountsPayable
    } = filterHistoryTemplate;
    tableControllers.paginationModel.page = 0;


    const modelFilter = createFilterModel({ reference, area, dateRange, projects, publicServices, grouping, action: userAction, customer: customerName as customerType, accountsPayable }) as filterModelType<FlatHistory>[];

    if (modifiedHistories.length > 0) {
      setIsFiltering(false);
      onConfirm();
      return;
    }

    setFilterModel(modelFilter as filterModelType<FlatHistory>[]);
  };

  const onCloseModalConfirmation = useCallback(() => {
    if (userAction) {
      setValue('accountsPayable', '');
      onCloseModal();
      setIsFiltering(false);
      setStatusToggle(false);
      const defaultFilter = getDefaultFilter(customerName, userAction);
      setFilterModel(defaultFilter);
      getData({ filterModel: defaultFilter, ...tableControllers });
      handleData({ filterModel: defaultFilter, ...tableControllers });
    }
  }, [userAction]);

  const onCloseModalConfirmationDissociate = () => {
    onCloseModal();
    getData({ filterModel, ...tableControllers });
    handleData({ filterModel, ...tableControllers });
  };

  const clearFilter = () => {
    reset();
    const defaultFilter = getDefaultFilter(customerName, userAction);
    setFilterModel(defaultFilter);
    getData({ filterModel: defaultFilter, ...tableControllers });
    handleData({ filterModel: defaultFilter, ...tableControllers });
  }

  useEffect(() => {
    reset();
    setValueTotal(0);
    setModifiedHistories([]);
    updateModifiedHistory(setModifiedHistories, []);
    setStatusToggle(false);
    if (userAction) {
      setValue('accountsPayable', '');
      const defaultFilter = getDefaultFilter(customerName, userAction);
      setFilterModel(defaultFilter);
      getData({ filterModel: defaultFilter, ...tableControllers });
      handleData({ filterModel: defaultFilter, ...tableControllers });
    }
  }, [userAction])

  useEffect(() => {
    setValueTotal(Number(modifiedHistories.reduce((acc, pay) => acc + Number(pay.value), 0)));
  }, [modifiedHistories]);

  return (
    <>
      <Table<FlatHistory>
        {...tableControllers}
        numberOfVisibleColumns={4}
        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        toggleOptions={getToggleOptions()}
        exportOptions={{
          filename: `Reporte-de pagos-pendientes_${joinObjectWithFormat(dateRange, '-', format, [
            DATE_FORMAT_BACK_WITH_OUT_TIME,
          ])}`,
          content: userAction && (<Button
            onClick={() => { }}
            type="button" variant="outlined" color="info">
            {`Total: $ ` + (valueTotal % 1 === 0 ? Math.floor(valueTotal).toLocaleString() : valueTotal.toLocaleString())}
          </Button>),
        }}
        filterComponent={

          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item xs={12}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-end"
                    spacing={2}
                    wrap="nowrap"
                  >
                    <Grid item xs={2}>
                      <Input
                        fullWidth
                        label="Contrato"
                        {...register('reference')}
                        error={!!errors.reference}
                        helperText={errors.reference && errors.reference.message}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Input
                        fullWidth
                        label="Inmueble"
                        {...register('grouping')}
                        error={!!errors.grouping}
                        helperText={errors.grouping && errors.grouping.message}
                      />
                    </Grid>
                    {/* <Grid item xs={2}>
                      <Input
                        fullWidth
                        label="CP"
                        value={accountsPayable}
                        {...register('accountsPayable')}
                        error={!!errors.accountsPayable}
                        helperText={errors.accountsPayable && errors.accountsPayable.message}
                      />
                    </Grid> */}
                    <Grid item xs={2}>
                      <Controller
                        name="area"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value}
                              onChange={field.onChange}
                              label="Área"
                              fullWidth
                              enableFilter
                            >
                              {AREAS
                                .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                                .map(({ value, name }) => (
                                  <MenuItem
                                    key={value}
                                    label={`${name}`}
                                    value={`${value}`}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="projects"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value
                              }
                              onChange={field.onChange}
                              label="Proyectos"
                              fullWidth
                              multiple
                              enableFilter
                            >
                              {projectsFetch ?
                                projectsFetch
                                  .sort((proj1, proj2) => (proj1.name > proj2.name) ? 1 : -1)
                                  .map(({ id, name }) => (
                                    <MenuItem
                                      key={name}
                                      label={`${name}`}
                                      value={`${id}`}
                                    >
                                      {name}
                                    </MenuItem>
                                  )) : <></>}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="publicServices"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value
                              }
                              onChange={field.onChange}
                              label="Compañía"
                              fullWidth
                              multiple
                              enableFilter
                            >
                              {companiesFetch ?
                                companiesFetch
                                  .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                                  .map(({ id, name }) => (
                                    <MenuItem
                                      key={name}
                                      label={`${name}`}
                                      value={`${id}`}
                                    >
                                      {name}
                                    </MenuItem>
                                  )) : <></>}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    {!cp && (<Grid item xs={4}>
                      <Controller
                        name="dateRange"
                        control={control}
                        render={({ field }) => (
                          <DateRangePicker
                            required
                            label="Fecha límite"
                            fullWidth
                            onChange={field.onChange}
                            value={field.value as DateRangePickerValue}
                            error={!!errors.dateRange}
                            allowFutureDates={true}
                            helperText={
                              errors.dateRange &&
                              (errors.dateRange.startDate?.message ||
                                errors.dateRange.endDate?.message)
                            }
                          />
                        )}
                      />
                    </Grid>)}
                    <Grid item>
                      <Button
                        info='Filtrar'
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        <AiOutlineSearch />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        info='Limpiar filtro'
                        onClick={clearFilter}
                        variant="contained"
                        color="primary"
                      >
                        <AiOutlineClear />
                      </Button>
                    </Grid>
                    {/* <Grid item>
                        <Button
                          isSmall
                          onClick={onDownload}
                          variant="contained"
                          color="primary"
                        >
                          Descargar
                        </Button>
                      </Grid> */}
                    {userAction && CLIENT_ACTIONS?.[customerName]?.[userAction] && (
                      <Grid item
                        // not display if there is no action
                        style={{ display: !userAction ? 'none' : 'flex' }}
                      >
                        <Button
                          info={CLIENT_ACTIONS?.[customerName]?.[userAction].name_action}
                          onClick={onConfirm}
                          variant="contained"
                          color="primary"
                          disabled={modifiedHistories.length === 0}
                        >
                          {CLIENT_ACTIONS?.[customerName]?.[userAction].icon}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item>
              {/* canEdit()  */ true && <Grid item>
                <IconButton
                  info="Actualiza fechas de pago"
                  color="primary"
                  onClick={() => onUpload()}
                  disabled={userAction ? true : false}
                >
                  <AiOutlineUpload />
                </IconButton>
              </Grid>}
              <Grid item>
                <IconButton
                  info="Descargar reportes históricos"
                  color="primary"
                  onClick={() => onDownload()}
                >
                  <AiOutlineCloudDownload />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={getDialogTitle(action)}
        maxWidth='md'
      >
        <>
          {!waitingResponse ? (
            <Grid container spacing={3} justify="flex-end">
              <Grid item xs={12}>
                {action === 'upload' && (
                  <HistoryUploadTemplate
                    setWaitingResponse={setWaitingResponse}
                    waitingResponse={waitingResponse}
                    action={action}
                    setAction={setAction}
                    open={open}
                    setOpen={setOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    setErrorMessage={setErrorMessage}
                  />
                )}
                {action === 'download' && (
                  <HistoryPendingDownloadTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    historyDownloadFilterModel={{
                      reference,
                      area,
                      projects,
                      publicServices,
                      grouping,
                      dateRange
                    }}
                    setErrorMessage={setErrorMessage}
                    projectList={projectsFetch}
                    publicServicesList={companiesFetch}
                  />
                )}
                {action === 'consumption' && (
                  <HistoryConsumptionTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    setErrorMessage={setErrorMessage}
                    consumptionList={consumptionList}
                  />
                )}
                {action === 'disassociate' && (
                  <HistoryConfirmationClientActionTemplate
                    setOpen={setOpen}
                    onClose={onCloseModalConfirmationDissociate}
                    setErrorMessage={setErrorMessage}
                    listHistory={modifiedHistories}
                    setModifiedHistory={setModifiedHistories}
                    setActionSnackBar={setActionSnackBar}
                    filterModel={filterModel}
                    setFilterModel={setFilterModel}
                    isFiltering={isFiltering}
                    currentFilterModel={filterModel}
                    action={''}
                    customer={customerName as customerType}
                    confirm={true}
                    idHistory={idHistory}
                    groupingName={groupingName}
                    projectName={projectName}
                    cp={cp}
                  />
                )}
                {action === 'confirm' && userAction && (
                  <HistoryConfirmationClientActionTemplate
                    setOpen={setOpen}
                    onClose={onCloseModalConfirmation}
                    setErrorMessage={setErrorMessage}
                    listHistory={modifiedHistories}
                    setModifiedHistory={setModifiedHistories}
                    setActionSnackBar={setActionSnackBar}
                    filterModel={filterModel}
                    setFilterModel={setFilterModel}
                    isFiltering={isFiltering}
                    currentFilterModel={filterModel}
                    action={userAction}
                    customer={customerName as customerType}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {action === 'downloadOne' && (
                  <HistoryTemplatePdf fileView={file} />
                )}
              </Grid>
            </Grid>
          ) : (
            <HistoryTemplateApiLoading />
          )}
        </>
      </Dialog>

      <SnackBar
        wait={4000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' && 'El proceso se realizó correctamente'}
        {open === 'warning' && errorMessage}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default HistoryPendingTemplate;
