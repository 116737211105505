import { Button, Grid, Typography } from 'components/atoms';
import { useState } from 'react';
import { Spinner } from "../../../../atoms";
import { CLIENT_ACTIONS } from '../HistoryPendingTemplate/ClientActions.const';
import { dissociateCPInvoice, updateHistories } from './HistoryConfirmationClientActionTemplate.const';
import { HistoryConfirmationClientActionTemplateProps } from './HistoryConfirmationClientActionTemplate.types';

const HistoryConfirmationClientActionTemplate = ({
  setOpen,
  onClose,
  setErrorMessage,
  listHistory,
  setModifiedHistory,
  setActionSnackBar,
  filterModel,
  setFilterModel,
  isFiltering,
  currentFilterModel,
  action,
  customer,
  confirm = false,
  idHistory,
  groupingName,
  projectName,
  cp,
  ...props
}: HistoryConfirmationClientActionTemplateProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const updatedButton = async () => {
    if (confirm && idHistory) {
      await dissociateCPInvoice({ idHistory, setOpen, setErrorMessage, setActionSnackBar })
      onClose();
    } else {
      await onUpdate()
    }

  }

  const onBatchUpdate = async () => {
    setIsUpdating(true);
    await updateHistories({
      listHistory,
      setModifiedHistory,
      setOpen,
      setErrorMessage,
      setActionSnackBar,
      setIsUpdating,
      action,
      customer,
    });
    setIsUpdating(false);
    setOpen('success');
    setActionSnackBar('activate');
  };

  const onUpdate = async () => {
    await onBatchUpdate();
    setFilterModel([...currentFilterModel]);
    onClose();
  };

  const onDiscard = () => {
    try {
      if (!confirm) {
        setModifiedHistory([]);
        setFilterModel([...currentFilterModel]);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getConfirmationText = () => {
    if (confirm) {
      return `¿Está seguro de que desea desasociar la factura del inmueble ${groupingName} del proyecto ${projectName} del ${cp}?`
    } else {
      let isFilteringMessage = isFiltering ? 'Antes de filtrar los inmuebles ' : '';

      const clientAction = CLIENT_ACTIONS?.[customer]?.[action];

      if (listHistory.length === 1) {
        return `${isFilteringMessage}¿Desea cambiar el estado de la factura del inmueble ${listHistory[0].groupingName} a '${clientAction?.state_to_update_name}'?`;
      } else {
        return `${isFilteringMessage}¿Deseas cambiar el estado de las facturas seleccionadas a '${clientAction?.state_to_update_name}'?`;
      }
    }
  };

  return (
    <>
      {!isUpdating ?
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant='h2'>
              {getConfirmationText()}
            </Typography>

          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info={confirm ? "Aceptar" : "Actualizar inmuebles"}
                color="primary"
                variant="contained"
                onClick={() => updatedButton()}
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item>
              <Button
                info={confirm ? "Cancelar" : "Descartar cambios"}
                color="primary"
                variant="contained"
                onClick={() => onDiscard()}
              >
                {confirm ? "Cancelar" : "Descartar cambios"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        : <Spinner></Spinner>
      }
    </>
  );
};

export default HistoryConfirmationClientActionTemplate;
