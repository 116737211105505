import axios, { AxiosRequestConfig } from 'axios';
import Toggle from 'components/atoms/Toggle';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT_BACK_WITH_OUT_TIME, DATE_FORMAT_WITH_OUT_TIME, DOWNLOAD_INVOICE, DOWNLOAD_INVOICES_COMBINED_PENDING, GET_HISTORY_PENDING_RECORDS, getCurrentTimeToString } from 'const';
import { format, parseISO } from 'date-fns';
import { filterModelType, tableModelType } from 'hooks/useTable';
import { AiOutlineBarChart, AiOutlineCloudDownload, AiOutlineDisconnect, AiOutlineEye } from 'react-icons/ai';
import { customerType, FlatHistory, PaginationType } from 'types';
import { Consumption } from 'types/entities/consumption';
import { createFilterStructure, createPayload, joinObjectWithFormat } from 'utils';
import { RequiredDateSchema } from 'yup/lib/date';
import { AnyObject, TypeOfShape } from 'yup/lib/object';
import { IconButton } from '../../../../atoms';
import { CLIENT_ACTIONS } from './ClientActions.const';
import { HistoryTemplateFilterType } from './HistoryPendingTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<FlatHistory> = {
  field: 'deadline',
  order: 'DESC',
};

export const DOWNLOADED = [
  { value: 'true', name: 'Descargado' },
  { value: 'false', name: 'Sin descargar' },
  { value: 'all', name: 'Todo' },
];

export type HistoryDownloadFilterModel = {
  reference: string | undefined,
  area: string | undefined,
  projects: string | undefined,
  publicServices: string | undefined,
  grouping: string | undefined,
  dateRange: TypeOfShape<{
    startDate: RequiredDateSchema<Date | undefined, AnyObject>;
    endDate: RequiredDateSchema<Date | undefined, AnyObject>;
  }>
  customer?: customerType | undefined,
  action?: string | undefined,
  accountsPayable?: string | undefined,
}


export const getDefaultHistoryTemplateFilter: (cp?: string) => HistoryTemplateFilterType = (cp) => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  let endDate = new Date();
  // last day of the next month
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setHours(23, 59, 59, 999);

  return {
    reference: '',
    accountsPayable: cp ?? '',
    area: '',
    projects: '',
    publicServices: '',
    grouping: '',
    isDownloaded: DOWNLOADED[1].value,
    dateRange: {
      startDate: startDate,
      endDate,
    },
    customer: undefined,
    action: undefined,
  };
}

export type HistoryDownloadAPIFilter = {
  downloaded: boolean;
  area?: string;
  dateRange: {
    startDate: Date | undefined,
    endDate: Date | undefined
  }
}

export const getColumns: (
  onDownload: (id: number, invoiceNumber: string) => Promise<void>,
  ref: React.MutableRefObject<HTMLAnchorElement | null>,
  url: string | undefined,
  name: string | undefined,
  onViewOne: (id: number, invoiceNumber: string) => Promise<void>,
  onViewConsumption: (consumption: Consumption[]) => void,
  action: string | undefined,
  customer: customerType | undefined,
  onCheck: (newState: boolean, sourceElement?: number | string | number[] | string[]) => void,
  updatedHistories: FlatHistory[],
  cp: string | undefined,
  onDisassociateCP: (id: number, groupingName: string, projectName: string) => Promise<void>,
) => columnType<FlatHistory>[] = (onDownload, ref, url, name, onViewOne, onViewConsumption, action, customer, onCheck, updatedHistories, cp, onDisassociateCP) => {
  const columns: columnType<FlatHistory>[] = [
    {
      field: 'area',
      headerName: 'Area',
      flex: '0.6',
      disabledSort: true,
      exportField: true,
      notDisplay: action ? true : false,
      isArea: true,
      render: ({ area }) => {
        switch (area) {
          case "UNIDAD":
            return "Unidad"
          case "OBRA":
            return "Obra"
          case "SALA_VENTA":
            return "Sala de venta"
          default:
            return ""
        }
      }
    },
    {
      field: 'projectName',
      headerName: 'Proyecto',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'groupingName',
      headerName: 'Inmueble',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'code',
      headerName: 'Compañía',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'accountsPayable',
      headerName: 'CP',
      flex: '0.8',
      exportField: true,
      disabledSort: true,
      isString: true,
      render: ({ accountsPayable }) => (
        <>
          {accountsPayable}
        </>
      )
    },
    {
      field: 'invoiceNumber',
      headerName: 'Factura',
      flex: '0.8',
      exportField: true,
      disabledSort: true,
      isString: true,
      notDisplay: true,
      render: ({ invoiceNumber }) => (
        <>
          {invoiceNumber}
        </>
      )
    },
    {
      field: 'deadline',
      headerName: 'Fecha límite',
      flex: '0.5',
      exportField: true,
      isDate: true,
      render: ({ deadline }) => {
        if (deadline !== null) {
          const datePart = deadline.split('T')[0];
          return format(parseISO(datePart), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS);
        } else {
          return '';
        }
      },
    },
    {
      field: 'value',
      headerName: 'Total Factura',
      flex: '0.7',
      exportField: true,
      align: 'flex-end',
      isCurrency: true,
      render: ({ value }) => (
        <>
          {value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString()}
        </>
      )
    }, {
      field: 'reference',
      headerName: 'Referencia',
      disabledSort: false,
      exportField: true,
      isString: true,
      notDisplay: true,
      render: ({ reference }) => (
        <>
          {reference}
        </>
      )
    }, {
      field: 'accountStatementNumber',
      headerName: 'Estado Cuenta No',
      disabledSort: false,
      exportField: true,
      isString: true,
      notDisplay: true,
      render: ({ accountStatementNumber }) => (
        <>
          {accountStatementNumber}
        </>
      )
    }, {
      field: 'electronicPaymentNumber',
      headerName: 'Pago Electrónico No',
      disabledSort: false,
      exportField: true,
      isString: true,
      notDisplay: true,
      render: ({ electronicPaymentNumber }) => (
        <>
          {electronicPaymentNumber}
        </>
      )
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: '0.5',
      exportField: true,
      disabledSort: true,
      isString: true,
      notDisplay: !!action,
      render: ({ status }) => (
        <>
          {status}
        </>
      )
    },
    {
      field: 'accountingPlan',
      headerName: customer && action ? CLIENT_ACTIONS?.[customer]?.[action]?.name_action || '' : '',
      flex: '0.7',
      overflowNone: true,
      isState: true,
      enableToggle: true,
      disabledSort: true,
      notDisplay: !action,
      render: ({ id }) => (
        <Toggle
          isChecked={updatedHistories.find(history => history.id === id) ? true : false}
          onToggleChange={onCheck}
          sourceElement={id}
          toggleSize='sm'
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acción',
      disabledSort: true,
      flex: `${cp !== '' ? '0.9' : 'O.6'}`,
      align: 'center',
      render: ({ id, invoiceNumber, consumption, accountsPayable, groupingName, projectName }) => (
        <>
          {consumption && consumption.length > 0 &&
            <IconButton
              info="Ver consumos"
              positionEdge="start"
              color="grey"
              onClick={() => onViewConsumption(consumption)}
            >
              <AiOutlineBarChart />
            </IconButton>
          }
          {cp && cp !== '' && accountsPayable && (
            <IconButton
              info="Desasociar"
              positionEdge="start"
              color="grey"
              onClick={() => onDisassociateCP(id, groupingName, projectName)}
            >
              <AiOutlineDisconnect />
            </IconButton>
          )}
          <IconButton
            info="Ver factura"
            positionEdge="start"
            color="grey"
            onClick={() => onViewOne(id, invoiceNumber)}
          >
            <AiOutlineEye />
          </IconButton>
          <a href={url} download={name} className="hidden" ref={ref} />
          <IconButton
            info="Descagar factura"
            positionEdge="start"
            color="grey"
            onClick={() => onDownload(id, invoiceNumber)}
          >
            <AiOutlineCloudDownload />
          </IconButton>
        </>
      ),
    },
  ];

  return columns;
}

export const createFilterModel = (
  { reference, area, dateRange, projects, publicServices, grouping, customer, action, accountsPayable }: HistoryDownloadFilterModel) => {


  let filterInvoiceNumber;

  if (reference?.includes(',')) {
    const invoiceNumbers = reference.split(',').map((invoice) => invoice.trim());
    filterInvoiceNumber = createFilterStructure(
      'reference',
      'in',
      invoiceNumbers,
      'companyServiceGrouping'
    );
  } else {
    filterInvoiceNumber = createFilterStructure(
      'reference',
      'contains',
      reference,
      'companyServiceGrouping'
    );
  }

  const filterArea = createFilterStructure(
    'area',
    'contains',
    area,
  );

  const filterDateRange = createFilterStructure(
    'deadline',
    'between_date',
    joinObjectWithFormat(dateRange, '.', format, [
      DATE_FORMAT_BACK_WITH_OUT_TIME,
    ]),
  );


  let modelFilter = [
    filterInvoiceNumber,
    filterArea,
    filterDateRange,
  ].filter((item) => item);

  if (accountsPayable) {
    const filterAccountsPayable = createFilterStructure(
      'accountsPayable',
      'contains',
      accountsPayable,
    );
    modelFilter = [
      filterAccountsPayable,
      ...modelFilter
    ]
  }

  if (projects) {
    const filterProject = createFilterStructure(
      'id',
      'in',
      projects?.split(','),
      'project',
    );
    modelFilter = [
      filterProject,
      ...modelFilter
    ]
  }

  if (grouping) {
    const filterProject = createFilterStructure(
      'name',
      'contains',
      grouping,
      'grouping',
    );
    modelFilter = [
      filterProject,
      ...modelFilter
    ]
  }

  if (publicServices) {
    const filterPublicService = createFilterStructure(
      'id',
      'in',
      publicServices?.split(','),
      'companyService',
    );
    modelFilter = [
      filterPublicService,
      ...modelFilter
    ].filter((item) => item);
  }

  if (action && customer && CLIENT_ACTIONS[customer]) {
    const actionData: string | undefined = CLIENT_ACTIONS[customer]?.[action]?.state;
    if (actionData) {
      const filterState = createFilterStructure(
        'code',
        'equals',
        actionData,
        'status'
      );
      modelFilter = [filterState, ...modelFilter].filter((item) => item);
    }
  }

  return modelFilter;
}

export type actions = 'remove' | 'edit' | 'upload' | 'download' | 'downloadOne' | 'consumption' | 'activate' | 'confirm' | 'disassociate' | undefined

export const getDialogTitle = (action: actions): string => {
  if (action === "upload") {
    return "Carga el documento";
  }
  if (action === "consumption") {
    return "Información de consumos";
  }
  if (action === "download") {
    return "Descarga de facturas pendientes por pagar";
  } else {
    return "";
  }
};

//If no sort model is sent, the default one is sent
export const fetchProcess = (
  pagination: tableModelType<FlatHistory>,
  config?: AxiosRequestConfig<FlatHistory>,
) => {

  if (!pagination.sortModel) {
    pagination.sortModel = defaultSortModel;
  }

  return axios.get<PaginationType<FlatHistory>>(
    `${GET_HISTORY_PENDING_RECORDS}${createPayload(pagination)}`,
    config,
  );
}

export const getFileNameByInvoiceNumber = (invoiceNumber: string) => {
  return `Factura_pendiente_${invoiceNumber}_${getCurrentTimeToString()}.pdf`;
};

export const downloadPdf = (
  filterModel: filterModelType<FlatHistory>[] | undefined
) => {
  return axios.get(
    `${DOWNLOAD_INVOICES_COMBINED_PENDING}${filterModel && filterModel.length > 0
      ? `?filter=${JSON.stringify(filterModel)}&`
      : ''
    }`,
    {
      responseType: "blob",
    }
  );
};

export const downloadOnePdf = (id: number) => {
  return axios.get(
    DOWNLOAD_INVOICE.replace(':id', id.toString()),
    {
      responseType: "blob",
    }
  );

};

export const updateModifiedHistory = (
  setModifiedHistory: (value: React.SetStateAction<FlatHistory[]>) => void,
  newHistories: FlatHistory[]
) => {
  setModifiedHistory((histories) => {
    const updatedHistories = [...histories];

    newHistories.forEach(newHistory => {
      const existingIndex = histories.findIndex(h => h.id === newHistory.id);
      if (existingIndex > -1) {
        // If the grouping already exists in modifiedGroupings, update its status
        updatedHistories[existingIndex] = newHistory;
      } else {
        // If the grouping doesn't exist in modifiedGroupings, add it
        updatedHistories.push(newHistory);
      }
    });
    return updatedHistories;
  });
}

export const getDefaultFilter = (customerName: customerType, userAction?: string, cp?: string): filterModelType<FlatHistory>[] => {
  const now = new Date();
  const endDate = new Date(now); // Copia de la fecha actual
  endDate.setMonth(endDate.getMonth() + 1); // Añadir 1 mes a la fecha actual

  const defaultFilter: filterModelType<FlatHistory>[] = [];

  // Filtro por rango de fechas si no se proporciona `cp`
  if (!cp) {
    defaultFilter.push({
      field: 'deadline',
      type: 'between_date',
      value: joinObjectWithFormat(
        { startDate: now, endDate },
        '.', // Delimitador para el formato
        format,
        [DATE_FORMAT_BACK_WITH_OUT_TIME]
      ),
    });
  }

  // Filtro por acción del cliente y estado
  if (userAction && CLIENT_ACTIONS?.[customerName]?.[userAction]) {
    const clientActionState = CLIENT_ACTIONS?.[customerName]?.[userAction]?.state;
    if (clientActionState) {
      defaultFilter.push({
        field: 'code',
        type: 'equals',
        value: clientActionState,
        table: 'status',
      });
    }
  }

  // Filtro por cuentas por pagar (cp)
  if (cp) {
    defaultFilter.push({
      field: 'accountsPayable',
      type: 'equals',
      value: cp,
    });
  }

  return defaultFilter;
};